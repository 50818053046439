
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Gallery.css'
import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';
import img1 from '../images/Rectangle 3852-min.png';
import img2 from '../images/r22-min.png';

import img3 from '../images/r33-min.png';
import img4 from '../images/r44-min.png';
import img5 from '../images/r55-min.png';
import img6 from '../images/r66-min.png';
import img7 from '../images/r77-min.png';
import img8 from '../images/r88-min.png';
import img9 from '../images/r99-min.png';
import img10 from '../images/r10-min.png';
import img11 from '../images/r11-min.png';
import img12 from '../images/r12-min.png';
import img13 from '../images/r13-min.png';
import m3 from '../images/m3-min.png';

import AOS from 'aos';
import 'aos/dist/aos.css';


 const Pic8 =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Welcome to WealthPath: Learn About Us</title>
      <meta name="description" content="Attend the WealthPath Conference to connect with finance experts and gain exclusive industry insights. Learn about us here!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <br /><br />
     <section className='gallery_sec'>
               <div style={{width:"80%",margin:"auto"}}>
                <img  style={{width:"100%"}} src={img8} alt="" />
                
         </div>
       
     </section>

   </div>
  )
}
export default Pic8;