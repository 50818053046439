
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/contact.css'
import '../components/Styles/Schedule.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Registration.css'

import {Helmet}from"react-helmet";
import wetp from '../components/Styles/wm1-min.png';
import wetp2 from '../components/Styles/wm2-min.png';
import ab_sec2img from '../images/ab_sec2img.png';


import AOS from 'aos';
import 'aos/dist/aos.css';


 const Registration =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Join Us at WealthPath 2024: Secure Your Spot</title>
      <meta name="description" content="Secure your spot at the WealthPath Conference! Register now to connect with top financial experts and unlock new wealth strategies. Don’t miss out!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>


      <section className='header_top'>
      <div className='headertp'>
        <h1 style={{paddingTop:"17%"}}>WealthPath Conference <br /> Registration</h1>
        <br />
      </div>
      </section>
     <br /><br /><br />
     <section className='reg_sec'>
     <div className='reg_sec2'>
       <label htmlFor="">First Name:</label>
       <br />
       
       <input type="text" />
       <br />
       <br />
       <label htmlFor="">Last Name:</label>
       <br />
       <input type="text" />
       <br />
       <br />
       <label htmlFor="">Phone Number:</label>
       <br />
       
       <input type="text" />
       <br />
       <br />
       <label htmlFor="">Email:</label>
       <br />
       <input type="text" />
       <br /><br />
       <label htmlFor="">Create Password:</label>
       <br />
       <input type="text" />
<br />
<br />
       <div className='reg_btn'>
       Register
       </div>
<br /><br /><br />
       <div className='reg_row'>
         <div className='reg_col'>
         <h2>Join Us</h2>
         <p>Whether you are a seasoned investor, a financial professional, or simply keen on learning more about Islamic finance, the WealthPath Conference is the place to be. We invite you to join us in shaping the future of ethical investing, one discussion at a time.
</p>
         </div>
         <div className='reg_col'>
         <img style={{width:"100%"}} src={ab_sec2img} alt="" /> 
</div>
       </div>
     
     </div>


     </section>
<br /><br />
<section className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>
    </div>
  )
}
export default Registration;