
import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/Schedule.css'
import '../components/Styles/AboutUs.css'
import '../components/Styles/Registration.css'
import '../components/Styles/Speaker.css'
import '../components/Styles/contact.css'
import prff from '../images/prff.png';

import {Helmet}from"react-helmet";

import speak1 from '../images/speak1-min.png';
import speak2 from '../images/speak2-min.png';
import speak3 from '../images/speak3-min.png';
import speak4 from '../images/speak4-min.png';
import speak5 from '../images/speak5-min.png';
import speak6 from '../images/speaker6-min.png';

import speak7 from '../images/dm1.png';
import speak8 from '../images/lub.jpeg';
import speak9 from '../images/lub2.jpg';

import ab_sec2img from '../images/ab_sec2img.png';


import AOS from 'aos';
import 'aos/dist/aos.css';


 const Speakers =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>Top Speakers at WealthPath Conference 2024 - Best Speakers</title>
      <meta name="description" content="Discover the best speakers at WealthPath Conference 2024, featuring industry leaders and experts in finance and wealth management. Join us!"/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>


      <section className='header_top'>
      <div className='headertp'>
      
        <h1 style={{paddingTop:"17%"}} data-aos="zoom-in">Speakers in WealthPath <br /> Conference</h1>
        <br />
      </div>
      </section>

     <br /><br /><br />
     <section className='reg_sec'>
     <div className='reg_sec2'>
     
    
     <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak6} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Dr. Main Alqudah</h2>
        <h3 data-aos="fade-right">Founding member of Guidance College in the U.S</h3>
        <p data-aos="fade-right">Dr. Main Alqudah is a founding member of Guidance College and a distinguished expert in Shariah-compliant finance. His international experience enriches his course, "Islamic Finance: Principles & Practice in the U.S.," which covers key concepts like interest prohibition and risk-sharing within the U.S. financial framework. Dr. Alqudah’s expertise highlights the integration of Islamic laws with contemporary financial systems, emphasizing practical applications and adherence to both Shariah and U.S. regulations.</p>
       </div>
     </div>

        <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak5} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Feras Moussa</h2>
        <h3 data-aos="fade-right">Tech-Savvy serial entrepreneur with a Computer Science degree</h3>
        <p data-aos="fade-right">Feras Moussa is a serial entrepreneur and former Microsoft Program Manager who helped shape the next generation of the web. As a co-founder of Disrupt Equity, he has successfully directed the acquisition of over 6,000 apartment units, with assets exceeding $800 million. Feras’s background in computer science and real estate investment underscores his diverse professional acumen.</p>
       </div>
     </div>
     <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak9} alt="" data-aos="fade-right" />
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Professor Gevorg Sargsyan</h2>
        <h3 data-aos="fade-right">Assistant Professor of Finance at Lamar University</h3> 
        <p data-aos="fade-right">Professor Gevorg Sargsyan is an Assistant Professor of Finance at Lamar University, specializing in various finance courses. He holds advanced degrees from the University of Alicante, Spain, and brings a rich blend of academic rigor and practical knowledge to his teachings. Professor Sargsyan is dedicated to enhancing students' understanding of complex financial concepts through innovative teaching methods.</p>
       </div>
     </div>

     <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak1} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Ala Salous</h2>
        <h3 data-aos="fade-right">Real Estate Investor and CEO of Investream</h3>
        <p data-aos="fade-right">Ala Salous, CEO and Co-Founder of Investream, manages a significant real estate portfolio valued at over $156 million. With extensive experience in Oil & Gas and transformative business leadership, Ala's expertise spans strategic acquisitions and development. An MBA graduate, he actively supports leadership and educational initiatives.</p>
       </div>
     </div>
   


        <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak3} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Derreck Long</h2>
        <h3 data-aos="fade-right">Senior Wealth Manager at Eckard Enterprises and a skilled private lender</h3>
        <p data-aos="fade-right">Derreck Long serves as Senior Wealth Manager at Eckard Enterprises, specializing in investment strategies and legislative analysis. A former military officer and global marketing graduate from Northern Arizona University, Derreck's finance expertise is complemented by experience with the FBI and in-depth knowledge of tax and legal frameworks.
</p>
       </div>
     </div>


     <div className='speak_row'>
       <div className='speak_col'>
        <img src={speak7} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Mohammad Ali</h2>
        <h3 data-aos="fade-right">Founder of 33 Percent Fund, seasoned Investor and Financial Analyst</h3>
        <p data-aos="fade-right">Mohammad Ali, founder of 33% Fund, is a seasoned investor and financial analyst. Starting as a stockbroker in NYC in the late 90s, he quickly became a top producer at Whale Securities in Boca Raton, Florida. His extensive knowledge of the financial markets inspired him to establish his own income hedge fund, focusing on dividend-earning option premium companies. Committed to financial literacy, Mohammad has authored "Investivus for the rest of us," addressing strategies for thriving in inflationary times.</p>
       </div>
     </div>
     
   
     <div className='speak_row'>
       <div className='speak_col'>
        <img style={{height:"30vh"}} src={speak8} alt="" data-aos="fade-right"/>
       </div>
       <div className='speak_col2'>
        <h2 data-aos="fade-right">Dr. Lubabah Abdullah </h2>
        <h3 data-aos="fade-right">Estate Planning Attorney with over 15 years of Experience</h3>
        <p data-aos="fade-right">Dr. Lubabah Abdullah is an estate planning attorney with over 15 years of experience, specializing in Islamic estate planning after studying with scholars from Al-Azhar University and other notable American-Muslim scholars. She is dedicated to providing culturally competent legal services and ensuring that estate plans respect her clients' faith and values.</p>
       </div>
     </div>
  
   
     
     </div>


     </section>
<br /><br />
<section className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>
    </div>
  )
}
export default Speakers;