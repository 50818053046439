import React, { useEffect } from 'react'
import {motion} from "framer-motion"
import '../components/Styles/home.css'
import '../components/Styles/review.css'
import '../components/Styles/contact.css'
import {Helmet}from"react-helmet";
import wetp from '../images/Rectangle 3 (1)-min.png';
import wetp2 from '../images/Rectangle 4 (1)-min.png';
import m3 from '../images/Rectangle 7 (1)-min.png';
import log1 from '../images/log1-min.png';
import log2 from '../images/log2-min.png';
import log3 from '../images/log3-min.png';
import log4 from '../images/log4-min.png';
import lgg1 from '../images/33logo.jpg';
import Slide from './Slide';
import AOS from 'aos';
import 'aos/dist/aos.css';
import FirebaseFirestore from './FirebaseFirestore'


 const Home =()=> {
  useEffect(()=>{
    AOS.init();
  })
  return (
    <div>
      <Helmet>
      
      <title>WealthPath Conference 2025: Empowering Your Financial Future</title>
      <meta name="description" content="Empower your financial journey at WealthPath Conference 2024. Gain insights into investing, wealth management, and financial planning from leading experts."/>
      < meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta charset="UTF-8"/>
      
      </Helmet>

      <section  className='header_top'>
      <div className='headertp'  >
         <h2 data-aos="fade-right">Navigating Diverse Investments in Accordance with Shariah</h2>
        <br />
        <h1 data-aos="zoom-in">Wealth Path Conference <br /> 2025</h1>
        <br />
        <h2 style={{paddingTop:"0%"}} data-aos="fade-left">Coming soon to Houston – Get ready for an <br/> exciting journey!</h2>
      </div>
      </section>
     
      <section className='med2'>
      <div className='med_mi'>
        <div className='rowwi'>
 
        <div className='mai_col'>
       <h2 data-aos="fade-right">
       Discover the Path to Ethical and Profitable Investing
       </h2>
       <p data-aos="fade-right">
       Join us at the WealthPath Conference, the premier gathering for investors, financial experts, and industry leaders focused on Shariah-compliant investments. Whether you're new to Islamic finance or seeking to enhance your knowledge, this event offers invaluable insights into lucrative and ethical investment strategies. Expand your expertise and discover the path to aligning your financial goals with your values.
       </p>
       </div>
      
      <div className='mai_col'>
     
        <img data-aos="fade-left" src={wetp} alt="" />
     
        <img id='wetp2' data-aos="fade-left" src={wetp2} alt="" />
      </div>
        </div>
        <div  style={{display:"none"}} className='boxxx'>
          <h3>15+</h3>
          <p>Expert Speakers In The <br />
          Last Conference</p>
        </div>   
     
      </div>
      </section>
<br /><br /><br /><br /><br /><br /><br />
      <section className='med2'>
        <div className='med_mi2'>
         <h2 data-aos="zoom-in">Why Attend?</h2>
        <div className='med_mi2_row'>
          <div className='med2_sec' >
            <h2 data-aos="fade-right">-Connect with Leading Investors:</h2>
            <p data-aos="fade-right">Network with experienced investors and professionals to build valuable relationships.</p>
            <h2 data-aos="fade-right">-Learn from Industry Experts:</h2>
            <p data-aos="fade-right">Gain insights from seasoned professionals sharing top strategies and knowledge.</p>
            <h2 data-aos="fade-right">-Participate in Dynamic Sessions:</h2>
            <p data-aos="fade-right">Participate in sessions on investment strategies and ethical finance principles.</p>
            <h2 data-aos="fade-right">-Showcase Your Business:  </h2>
            <p data-aos="fade-right">Sponsor a stall at our event and market your business to a highly engaged audience of investors and professionals.</p>
          </div>
          <div className='med2_sec' data-aos="fade-left">
            <img src={m3} alt="" />
          </div>
        </div>
        </div>
     
      </section>
<br /><br />
      <section className=''>
       <div data-aos="zoom-in" className='med_mi3'>
        <h2 id='med33'>Conference Highlights</h2>
        <br />
        <div className='med3row'>
         <div className='col_med3'>
          <img src={log1} alt="" />
         </div>
         <div className='col_med3'>
         <img src={log2} alt="" />
         </div>
         <div className='col_med3'>
         <img src={log3} alt="" />
         </div>
         <div className='col_med3'>
         <img src={log4} alt="" />
         </div>
            </div>

            <div className='med3row'>
             <div className='col_med3'>
              <h2>Inspiring <br />
              Keynotes:</h2>
             </div>
             <div className='col_med3'>
              
              <h2>Interactive <br />Panels: </h2>
             </div>
             <div className='col_med3'>
              <h2>Strategic <br /> 
              Networking:</h2>
             </div>
             <div className='col_med3'>
              <h2>Business <br /> 
              Pitch:</h2>
             </div>
          
            </div>

            <div className='med3row' data-aos="zoom-in">
             <div className='col_med3' >
             
<p>Hear insights from leading experts in ethical finance.</p>
             </div>
             <div className='col_med3'>
              
             
<p>Discuss critical topics like Real Estate vs. Stocks.</p>
             </div>
             <div className='col_med3'>
             
<p>Connect through planned social events and breaks.</p>
             </div>
             <div className='col_med3'>
             
<p>Showcase your  ideas in a Shark Tank-style pitch.</p>
             </div>
          
            </div>
       </div>
      </section>

<br /><br />
      <section className=''>

        <div className='medd4'>
        <h2 data-aos="zoom-in">For Sponsors</h2>
        <br />
        <p data-aos="fade-right">Unlock exclusive benefits and showcase your brand to a highly targeted audience. Learn more about our sponsorship opportunities and how you can make a significant impact at the WealthPath Conference.
        </p>



 <div style={{width:"40%",margin:"auto"}}>
 <img style={{width:"100%",textAlign:"center"}} src={lgg1} alt="" />

 </div>
  

    </div>
      </section>
      <br /><br />
      <section style={{display:"none"}} className="">
            <div className="con">
              <h2 >Register Now!</h2>
              <p>Don’t miss out on the opportunity to be part of this cutting-edge conference. Secure your spot today and start your journey on the WealthPath to successful and ethical investing.</p>
             <br />
              <div className="reg">
              <button id="eventbrite-widget-modal-trigger-890266861147" type="button">Buy Tickets</button>
            </div>
            </div>
          </section>
<br />
<section>
  <FirebaseFirestore/>
</section>

<section className='lastmain'>
<h3 className='revviw' style={{textAlign:"center"}}>Reviews</h3>

     <div className='lst_row'>
      
      <div data-aos="fade-right" className='lst2_col'>
      
      </div>
           </div>
<br />
<Slide />
<br />
    </section>

    </div>
  )
}
export default Home;