import React from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import AboutUs from '../pages/AboutUs';

import GeneralEngineering from '../pages/GeneralEngineering';
import Home from '../pages/Home';
import Home2 from '../pages/Home2';
import InteriorRemodeling from '../pages/InteriorDesign';
import OurTeam from '../pages/OurTeam';
import Services from '../pages/Services';
import Structural from '../pages/Structural';
import Schedule from '../pages/Schedule';
import Registration from '../pages/Registration';
import Sponsorship from '../pages/Sponsorship';
import Speakers from '../pages/Speakers';
import FirebaseFirestore from '../pages/FirebaseFirestore';
import Gallery from '../pages/Gallery';
import Gallery2 from '../pages/Gallery2';
import Pic1 from '../pages/Pic1';
import Pic2 from '../pages/Pic2';
import Pic3 from '../pages/Pic3';
import Pic4 from '../pages/Pic4';
import Pic5 from '../pages/Pic5';
import Pic6 from '../pages/Pic6';
import Pic7 from '../pages/Pic7';
import Pic8 from '../pages/Pic8';
import Pic9 from '../pages/Pic9';
import Pic10 from '../pages/Pic10';
import Pic11 from '../pages/Pic11';
import Pic12 from '../pages/Pic12';
import Pic13 from '../pages/Pic13';

const Routers =()=> {
  return (
    <Routes>
    <Route path='/' element={<Home/>} />
      <Route path='home' element={<Home/>} />

      <Route path='about-us' element={<AboutUs/>} />
      <Route path='services' element={<Services/>} />
      <Route path='our-team' element={<OurTeam/>} />
      <Route path='general-construction' element={<GeneralEngineering/>} />
      <Route path='interior-remodeling' element={<InteriorRemodeling/>} />
      <Route path='structural' element={<Structural/>} />
      <Route path='schedule' element={<Schedule/>} />
      <Route path='Registration' element={<Registration/>} />
      <Route path='Sponsorship' element={<Sponsorship/>} />
      <Route path='Speakers' element={<Speakers/>} />
      <Route path='Gallery' element={<Gallery/>} />
      <Route path='secret' element={<Gallery2/>} />
      <Route path='Form' element={<FirebaseFirestore/>} />
      <Route path='Picture' element={<Pic1/>} />
      <Route path='Picture-2' element={<Pic2/>} />
      <Route path='Picture-3' element={<Pic3/>} />
      <Route path='Picture-4' element={<Pic4/>} />
      <Route path='Picture-5' element={<Pic5/>} />
      <Route path='Picture-6' element={<Pic6/>} />
      <Route path='Picture-7' element={<Pic7/>} />
      <Route path='Picture-8' element={<Pic8/>} />
      <Route path='Picture-9' element={<Pic9/>} />
      <Route path='Picture-10' element={<Pic10/>} />
      <Route path='Picture-11' element={<Pic11/>} />
      <Route path='Picture-12' element={<Pic12/>} />
      <Route path='Picture-13' element={<Pic13/>} />
    </Routes>
  )
}
export default Routers;